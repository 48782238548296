import React from 'react'
import { MDXTag } from '@mdx-js/tag'



export const frontmatter = {
  title: 'Effects API',
  author: 'Chris Neale',
  draft: false,
  date: '2018-11-03T23:47:37.121Z',
  tags: ['neon', 'effects']
}
export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}>
<MDXTag name="h3" components={components}>{`Effects API`}</MDXTag>
<MDXTag name="p" components={components}>{`Coming soon...`}</MDXTag></MDXTag>

export const _frontmatter = {};

  